<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Vote Kick</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="search"
        label="Search"
        single-line
        v-on:keyup.enter="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="searchTypeSelect"
        :items="searchTypes"
        :loading="$store.state.progress"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Search Type"
      ></v-combobox>
      <!-- <v-combobox
        v-model="$store.state.gameLogs.pageItemSelect"
        :items="pageItems"
        item-value="id"
        item-text="value"
        @change="initialize"
        hide-details
        label="Page"
      ></v-combobox> -->
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="all"
      :page.sync="page"
      @page-count="pageCount = $event"
      :options.sync="options"
      hide-default-footer
      :items-per-page="itemsPerPage"
      :server-items-length="$store.state.gameLogs.voteKickAll.totalPage"
      class="elevation-1"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [50, 100, 500, 1000, 2000],
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
      }"
      item-key="t"
    >
      <template v-slot:item.createTime="{ item }">
        {{ new Date(item.createTime * 1000) | formatDateSS }}
      </template>
      <template v-slot:item.vote="{ item }">
        <div style="display: flex;">
          <div
            v-for="(c, index) in item.vote.filter(
              (m) => m.userId != item.from && m.userId != item.to
            )"
            :key="index"
          >
            <div
              style="position:relative"
              :class="
                `card-p ${
                  c.choice == 1
                    ? 'green-card'
                    : c.choice == 0
                    ? 'orange-card'
                    : 'red-card'
                }`
              "
            >
              <div style="text-align: center;font-weight: bold;">
                {{ c.userId }}
              </div>
              <v-avatar size="68" style="margin:0 17px;">
                <v-img
                  v-if="c.pictureUrl.startsWith('http')"
                  :src="`${c.pictureUrl}`"
                />
                <v-img
                  v-else-if="c.pictureUrl.startsWith('/')"
                  :src="`https://pokerface.restplay.com${c.pictureUrl}`"
                />
                <v-img
                  v-else
                  :src="
                    `https://pokerface.restplay.com/Avatars/${c.pictureUrl}`
                  "
                />
              </v-avatar>
              <div
                style="text-align: center;font-weight: bold; font-size:15px;text-overflow: ellipsis;white-space: nowrap; 
  overflow: hidden;"
              >
                {{ c.userName }}
              </div>
              <div
                style="text-align:center;font-weight: bold;color:yellow;font-size:15px;"
              >
                {{ c.level }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:item.from="{ item }">
        <div style="display:flex;">
          <div v-if="item.from" style="display: flex; ">
            <div style="position:relative" :class="`card-p green-card`">
              <div style="text-align: center;font-weight: bold;">
                {{ item.from }}
              </div>
              <v-avatar size="68" style="margin:0 17px;">
                <v-img
                  v-if="item.fromPictureUrl.startsWith('http')"
                  :src="`${item.fromPictureUrl}`"
                />
                <v-img
                  v-else-if="item.fromPictureUrl.startsWith('/')"
                  :src="`https://pokerface.restplay.com${item.fromPictureUrl}`"
                />
                <v-img
                  v-else
                  :src="
                    `https://pokerface.restplay.com/Avatars/${item.fromPictureUrl}`
                  "
                />
              </v-avatar>
              <div
                style="text-align: center;font-weight: bold; font-size:15px;text-overflow: ellipsis;white-space: nowrap; 
  overflow: hidden;"
              >
                {{ item.fromUserName }}
              </div>
              <div
                style="text-align:center;font-weight: bold;color:yellow;font-size:15px;"
              >
                {{ item.fromLevel }}
              </div>
            </div>
          </div>
          <v-icon>mdi-forward</v-icon>
          <div v-if="item.to" style="display: flex; padding-left:10px;">
            <div style="position:relative" :class="`card-p red-card`">
              <div style="text-align: center;font-weight: bold;">
                {{ item.to }}
              </div>
              <v-avatar size="68" style="margin:0 17px;">
                <v-img
                  v-if="item.toPictureUrl.startsWith('http')"
                  :src="`${item.toPictureUrl}`"
                />
                <v-img
                  v-else-if="item.toPictureUrl.startsWith('/')"
                  :src="`https://pokerface.restplay.com${item.toPictureUrl}`"
                />
                <v-img
                  v-else
                  :src="
                    `https://pokerface.restplay.com/Avatars/${item.toPictureUrl}`
                  "
                />
              </v-avatar>
              <div
                style="text-align: center;font-weight: bold; font-size:15px;text-overflow: ellipsis;white-space: nowrap; 
  overflow: hidden;"
              >
                {{ item.toUserName }}
              </div>
              <div
                style="text-align:center;font-weight: bold;color:yellow;font-size:15px;"
              >
                {{ item.toLevel }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :total-visible="20"
        :length="$store.state.gameLogs.voteKickAll.totalPage"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
export default {
  name: "VoteKickListCard",
  props: {
    search: {
      type: String,
    },
  },
  data() {
    return {
      all: [],
      options: {},
      page: 1,
      pageCount: 0,
      itemsPerPage: 50,
      searchTypeSelect: { id: -1, value: "All" },
      searchTypes: [
        { id: 0, value: "All" },
        { id: 1, value: "From User" },
        { id: 2, value: "To User" },
      ],
      headers: [
        {
          text: "roomId",
          align: "left",
          sortable: false,
          value: "RoomId",
        },
        { text: "Room Name", value: "roomName" },
        { text: "From => To", value: "from" },
        { text: "CreateTime", value: "createTime" },
        { text: "IsKicked", value: "isKicked" },
        { text: "VoteIndex", value: "voteIndex" },
        { text: "Vote", value: "vote" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  methods: {
    async deleteItem() {},
    editItem() {},
    async initialize() {
      // eslint-disable-next-line no-unused-vars
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      await this.$store.dispatch("gameLogs/getVoteKickAll", {
        search: this.search,
        page: page - 1,
        total: itemsPerPage,
        options: this.options,
        searchTypeSelect: this.searchTypeSelect.id,
      });
      this.all = this.$store.state.gameLogs.voteKickAll.data;
    },
  },

  async mounted() {},
};
</script>
